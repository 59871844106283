import React, { useState } from 'react';
import './Header.css'
import Logo from '../Assets/Logo.png'
import { NavHashLink as Link } from 'react-router-hash-link';

export default function Header() {
    const [active, setActive] = useState('')
    const [isOpen, setIsOpen] = useState(false);
    return (
        <div className="bg">
            <div className='Navbar container-lg container-fluid-lg'>
                <Link
                    smooth={true}
                    to="#home">
                    <span className="nav-logo">
                        <img src={Logo} alt="Safeer Arts Logo" />
                    </span>
                </Link>
                <div className={`nav-items ${isOpen && "open"}`}>
                    <Link
                        smooth={true}
                        to="#home"
                        onClick={() => { setActive('home'); setIsOpen(!isOpen) }}
                        className={`text-uppercase font-Blair fw-bold ${active === 'home' ? 'set-active' : ''}`}>
                        Home
                    </Link>
                    <Link
                        smooth={true}
                        to="#about"
                        onClick={() => { setActive('about'); setIsOpen(!isOpen) }}
                        className={`text-uppercase font-Blair fw-bold ${active === 'about' ? 'set-active' : ''}`}>
                        About
                    </Link>
                    <Link
                        smooth={true}
                        to="#portfolio"
                        onClick={() => { setActive('portfolio'); setIsOpen(!isOpen) }}
                        className={`text-uppercase font-Blair fw-bold ${active === 'portfolio' ? 'set-active' : ''}`}>
                        Portfolio
                    </Link>
                    <Link
                        smooth={true}
                        to="#testimonial"
                        onClick={() => { setActive('testimonial'); setIsOpen(!isOpen) }}
                        className={`text-uppercase font-Blair fw-bold ${active === 'testimonial' ? 'set-active' : ''}`}>
                        Testimonial
                    </Link>
                    <Link
                        smooth={true}
                        to="#contact"
                        onClick={() => { setActive('contact'); setIsOpen(!isOpen) }}
                        className={`text-uppercase font-Blair fw-bold ${active === 'contact' ? 'set-active' : ''}`}>
                        Contact
                    </Link>
                </div >
                <div
                    className={`nav-toggle ${isOpen && "open"}`}
                    onClick={() => setIsOpen(!isOpen)}
                >
                    <div className="bar"></div>
                </div>
            </div >
        </div >
    )
}
