import React, { useState } from 'react';
import Line from '../Line/Line';
import './Testimonials.css'
import Apostrophe from '../Assets/Portfolio-images/Testimonials/Apostrophe.png'
import testimonial from '../Utils/testimonials'
import { motion } from 'framer-motion'

function Testimonials() {
    const [addIndex, setAddIndex] = useState(0)
    const sliderRight = () => {
        const num = addIndex + 1
        const number = setAddIndex(num);
        if (num === testimonial.length + 1) {
            const setnumber = setAddIndex(0);
        } else {
            if (testimonial[num] === undefined) {
                const setnumber = setAddIndex(0);
            }
        }
    }
    const sliderLeft = () => {
        const num = addIndex - 1
        const number = setAddIndex(num);
        if (num === -1) {
            const len = testimonial.length;
            setAddIndex(len - 1)
        }
    }
    return (
        <>
            <h1 className='text-uppercase text-center font-Blair py-3 fw-bold' id='testimonial'>Testimonials</h1>
            <Line />
            <div className='testimonial-section px-sm-5 py-5 px-5 d-flex justify-content-center align-items-center' >
                <div className="row adj-padding d-flex justify-content-center align-items-center px-5 position-relative">
                    <i className="fas fa-angle-left hover padding-zero" onClick={sliderLeft} ></i>
                    <div className="col-lg-8 py-4 px-2 padding-zero text-justify">
                        <img src={Apostrophe} alt="Apostrophe" />
                        <h2 className='py-3 font-15 font-helvetica grey px-5 px-sm-5 pt-5 adj-padding'>{testimonial[addIndex].review}</h2>
                        <p className='color-red font-helvetica px-5 pb-5 font-15-bold text-uppercase font-sm-22'>{testimonial[addIndex].clienName}</p>
                    </div>
                    <div className="col-lg-4 d-flex justify-content-center align-items-center px-2 padding-zero">
                        <motion.img whileHover={{ scale: 1.1 }}
                            whileTap={{ scale: 0.9 }} className='review-img' src={testimonial[addIndex].testimonial} alt="Book Cover, Audio Books" />
                    </div>
                    <i className="fas fa-angle-right hover padding-zero" onClick={sliderRight}></i>
                </div>
            </div>
            <Line />
        </>
    );
}

export default Testimonials;
