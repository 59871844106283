const testimonial = [
    {
        testimonial: require("../Assets/Portfolio-images/Testimonials/1.png"),
        review: `"I chose Safeer to create the cover for my next novel. I really appreciated the effort he put in to understand the story and included as many elements as he could into the design while keeping it clear and beautiful. Greatly appreciated!"`,
        clienName: '- Victoria Bernadine'
    },
    {
        testimonial: require("../Assets/Portfolio-images/Testimonials/2.png"),
        review: `"SafeerAhmed provided a superb book cover that captured my requirements perfectly. Only a few adjustments were needed and he provided them quickly. I highly recommend this artist to anyone neededing a book cover. Well done!"`,
        clienName: '- Bonnie shiloh'
    },
    {
        testimonial: require("../Assets/Portfolio-images/Testimonials/3.png"),
        review: `"From the moment I saw this design it grabbed me! He took everything that I had requested into consideration and nailed it. He was so quick to respond, and an absolute pleasure to work with. I'm sure that we will be collaborating again in the near future :)"`,
        clienName: '- Robin whitt'
    },
    {
        testimonial: require("../Assets/Portfolio-images/Testimonials/4.png"),
        review: `"Safeer is serious, professional, accomplished and works according to instructions. He is also creative and amazingly responsive. I highly recommend him for book covers, and this was my tenth set of covers."`,
        clienName: '- William keiper'
    },
]
export default testimonial;
