import './Contact.css'
import { useRef, useState } from 'react'
import emailjs from '@emailjs/browser'

export default function Contact() {

    const form = useRef();
    const [msg, setMsg] = useState('')

    const submit = (e) => {
        e.preventDefault()
        emailjs.sendForm('service_ss8w6au', 'template_g7n79wv', form.current, 'user_GCWFs13nspV9EyRaD5btj')
            .then((result) => {
                console.log(result.text);
                setMsg('Email was sent to us. We will replay ASAP!')
            }, (error) => {
                console.log(error.text);
            });
        e.target.reset()
    }

    return (
        <div className="contact-section py-5 container px-5" id='contact'>
            <h1 className="text-uppercase text-center font-Blair fw-bold">Contact</h1>
            <form ref={form} onSubmit={submit}>
                <div className="form-row row px-5 sm-px-0 lg-px-0">
                    <div className="col-lg-6 col-md-12 py-3 sm-px-0 lg-px-0">
                        <input type="text" className="form-control" placeholder="Name *" name='name' required />
                    </div>
                    <div className="col-lg-6 col-md-12 py-3 sm-px-0 lg-px-0">
                        <input type="email" className="form-control" placeholder="Email *" name='email' required />
                    </div>
                    <div className="col-lg-12 py-3 sm-px-0 lg-px-0">
                        <input type="text" className="form-control" placeholder="Subject" name='subject' required />
                    </div>
                    <div className="col-lg-12 py-3 sm-px-0 lg-px-0">
                        <textarea type="text" className="form-control" placeholder="Message" id="exampleFormControlTextarea1" rows="3" name='message' required />
                    </div>
                    <div className="col-lg-12 py-3 text-center">
                        <button className="btn btn-danger mb-2 button">Submit</button>
                    </div>
                    {
                        msg === "" ? (<div style={{ display: 'none' }}></div>) : (<div className="msg d-flex justify-content-center">
                            <h1>{msg}</h1> <span className='close' onClick={() => setTimeout(setMsg(''), 5000)}>X</span>
                        </div>)
                    }
                </div>
            </form>
        </div>
    )
}