const others = [
    {
        welcome: "WELCOME TO",
        after: "SAFEER ART",
        hero: require("../Assets/Book images.png")

    },
    {
        About: {
            h1: "My name is Safeer Ahmed, I am a graphic designer who specializes in book cover designs.",
            h2: `I have over five years of experience designing book covers, Promotion Posts, Social  advertising, Audiobooks, Podcast covers, web design and more. I have worked with numerous authors and publishers and have helped them make their vision come true.`,
            h3: "Check out my portfolio to see some of my work. If you have a project in mind, please feel free toget in touch.",
        }
    },
    {
        rightsReserved: "Safeer Art - All Rights Reserved © 2022"
    },
    {
        socials: {
            facebook: "https://www.facebook.com/safeerrahmed-112213373834029",
            instagram: "https://www.instagram.com/safeerrahmed/",
            linkedIn: "https://www.linkedin.com/in/safeer-ahmed-2b431515a/"
        }
    }
]
export default others;