import Images from "../Utils/Images"
import { useState } from "react"
import './Portfolio.css'
import { motion } from "framer-motion";

export default function Portfolio() {
    const [view, setView] = useState("booksCover");

    const imagesMaping = (setState) => {
        setView(setState)
    }

    return (
        <>
            <div className="portfolio-section" id="portfolio">
                <div className="d-flex justify-content-center align-items-center">
                    <ul className="breadcrumb-list d-flex flex-row flex-wrap py-5">
                        <li className={`text-uppercase pointer font-Blair fw-bold ${view === "booksCover" ? 'active underline-active' : ''}`} onClick={() => { imagesMaping('booksCover') }}>Book Covers</li>
                        <li className="text-uppercase hidden">/</li>
                        <li className={`text-uppercase pointer font-Blair fw-bold ${view === "audioBooks" ? 'active underline-active' : ''}`} onClick={() => { imagesMaping('audioBooks') }}>Audio Book</li>
                        <li className="text-uppercase hidden">/</li>
                        <li className={`text-uppercase pointer font-Blair fw-bold ${view === "typography" ? 'active underline-active' : ''}`} onClick={() => { imagesMaping('typography') }}>Typography</li>
                    </ul>
                </div>
                <div className="container row py-5">
                    {
                        view === "booksCover" && Images.map((img, key) => (
                            <motion.div whileHover={{ scale: 1.1 }}
                                whileTap={{ scale: 0.9 }} key={key} className={`col-xl-3 col-lg-4 col-md-6 col-sm-12 py-4 ${img.booksCovers ? " d-flex justify-content-center align-items-center" : "display-none"}`}>
                                <img src={img.booksCovers} alt="Book Cover" />
                            </motion.div>
                        ))
                    }
                    {
                        view === "audioBooks" && Images.map((img, key) => (
                            <motion.div whileHover={{ scale: 1.1 }}
                                whileTap={{ scale: 0.9 }} key={key} className={`col-xl-3 col-lg-4 col-md-6 col-sm-12 py-4 ${img.audioBooks ? " d-flex justify-content-center align-items-center" : "display-none"}`}>
                                <img src={img.audioBooks} alt="Audio Books Cover" />
                            </motion.div>
                        ))
                    }
                    {
                        (view === "typography" && Images.map((img, key) => (
                            <motion.div whileHover={{ scale: 1.1 }}
                                whileTap={{ scale: 0.9 }} key={key} className={`col-xl-3 col-lg-4 col-md-6 col-sm-12 py-4 ${img.typography ? " d-flex justify-content-center align-items-center" : "display-none"}`}>
                                <img src={img.typography} alt="Typography" />
                            </motion.div>)
                        ))
                    }
                </div>
            </div>
        </>
    )
}