import './Hero.css'
import Line from '../Line/Line.jsx'
import others from '../Utils/othersUtils'
import { motion, AnimatePresence } from "framer-motion"

export default function Hero() {
    return (
        <>
            <div className="hero-bg d-flex flex-column justify-content-center align-items-center pt-sm-5">
                <div className="row hero-section d-flex flex-row justify-content-center align-items-center container-xl container-xxl container-lg" id="home">
                    <AnimatePresence>
                        <motion.div
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                            className="col-md-6 hero-left-area d-flex justify-content-center align-items-center">
                            <h1 className="display-5 hero-text text-uppercase font-Blair p-0 fw-bold">{others[0].welcome} <span className="color-red text-uppercase">{others[0].after}</span></h1>
                        </motion.div>
                        <motion.div initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }} className="col-md-6 hero-right-area d-flex justify-content-center align-items-center">
                            <img src={others[0].hero} className="hero-img" alt='hero-image' />
                        </motion.div>
                    </AnimatePresence>
                </div >
                <Line />
            </div>
        </>
    )
}