import './App.css';
import Header from './Components/Header/Header.jsx';
import Hero from './Components/Hero/Hero.jsx';
import About from './Components/About/About.jsx';
import Portfolio from './Components/Portfolio/Portfolio.jsx';
import Contact from './Components/Contact/Contact.jsx';
import Footer from './Components/Footer/Footer.jsx';
import Testimonials from './Components/Testimonials/Testimonials';
import './Components/Assets/fonts/blair-itc-medium.ttf';
import './Components/Assets/fonts/HelveticaNeueLTProEx.otf';

function App() {
  return (
    <>
      <Header />
      <Hero />
      <About />
      <Portfolio />
      <Testimonials />
      <Contact />
      <Footer />
    </>
  );
}

export default App;
