import './About.css'
import Line from '../Line/Line.jsx'
import others from '../Utils/othersUtils'
import { motion } from "framer-motion"

export default function About() {
    return (
        <>
            <div className="row about-section d-flex justify-content-center py-5" id="about">
                <div className="col-xl-8 col-lg-8 col-md-12 about d-flex flex-column justify-content-center">
                    <h1 className='font-Blair text-uppercase fw-bold text-justify white'>about</h1>
                    <div className='d-flex flex-column justify'>
                        <p className="h6 py-2 font-helvetica padding-zero grey">
                            {others[1].About.h1}
                        </p>
                        <p className="h6 py-2 font-helvetica padding-zero grey">
                            {others[1].About.h3}
                        </p>
                        <p className="h6 py-2 font-helvetica padding-zero grey">
                            {others[1].About.h2}
                        </p>
                    </div>
                </div>
                <motion.div whileHover={{ scale: 0.8 }}
                    whileTap={{ scale: 0.5 }} className="profile col-xl-4 col-lg-4 col-md-12">
                </motion.div>
            </div>
            <Line />
        </>
    )
}