const images = [
    // BookCover images links
    {
        booksCovers: require("../Assets/Portfolio-images/Book Covers/Book-1.png"),
    },
    {
        booksCovers: require("../Assets/Portfolio-images/Book Covers/Book-2.png"),
    },
    {
        booksCovers: require("../Assets/Portfolio-images/Book Covers/Book-3.png"),
    },
    {
        booksCovers: require("../Assets/Portfolio-images/Book Covers/Book-4.png"),
    },
    {
        booksCovers: require("../Assets/Portfolio-images/Book Covers/Book-5.png"),
    },
    {
        booksCovers: require("../Assets/Portfolio-images/Book Covers/Book-6.png"),
    },
    {
        booksCovers: require("../Assets/Portfolio-images/Book Covers/Book-7.png"),
    },
    {
        booksCovers: require("../Assets/Portfolio-images/Book Covers/Book-8.png"),
    },
    {
        booksCovers: require("../Assets/Portfolio-images/Book Covers/Book-9.png"),
    },
    {
        booksCovers: require("../Assets/Portfolio-images/Book Covers/Book-10.png"),
    },
    {
        booksCovers: require("../Assets/Portfolio-images/Book Covers/Book-11.png"),
    },
    {
        booksCovers: require("../Assets/Portfolio-images/Book Covers/Book-12.png")
    },
    // Audio Books images links
    {
        audioBooks: require("../Assets/Portfolio-images/Audio Books/1.png")
    },
    {
        audioBooks: require("../Assets/Portfolio-images/Audio Books/2.png")
    },
    {
        audioBooks: require("../Assets/Portfolio-images/Audio Books/3.png")
    },
    {
        audioBooks: require("../Assets/Portfolio-images/Audio Books/4.png")
    },
    {
        audioBooks: require("../Assets/Portfolio-images/Audio Books/5.png")
    },
    {
        audioBooks: require("../Assets/Portfolio-images/Audio Books/6.png")
    },
    {
        audioBooks: require("../Assets/Portfolio-images/Audio Books/7.png")
    },
    {
        audioBooks: require("../Assets/Portfolio-images/Audio Books/8.png")
    },
    {
        audioBooks: require("../Assets/Portfolio-images/Audio Books/9.png")
    },
    {
        audioBooks: require("../Assets/Portfolio-images/Audio Books/10.png")
    },
    {
        audioBooks: require("../Assets/Portfolio-images/Audio Books/11.png")
    },
    {
        audioBooks: require("../Assets/Portfolio-images/Audio Books/12.png")
    },
    {
        audioBooks: require("../Assets/Portfolio-images/Audio Books/13.png")
    },
    {
        audioBooks: require("../Assets/Portfolio-images/Audio Books/14.png")
    },
    {
        audioBooks: require("../Assets/Portfolio-images/Audio Books/15.png")
    },
    // Typography images links
    {
        typography: require("../Assets/Portfolio-images/Typography/1.png")
    },
    {
        typography: require("../Assets/Portfolio-images/Typography/2.png")
    },
    {
        typography: require("../Assets/Portfolio-images/Typography/3.png")
    },
    {
        typography: require("../Assets/Portfolio-images/Typography/4.png")
    },
    {
        typography: require("../Assets/Portfolio-images/Typography/5.png")
    },
]
export default images;
