import "./Footer.css"
import footer from '../Assets/Footer-Logo.png'
import others from '../Utils/othersUtils'

export default function Footer() {
    return (
        <div className="footer d-flex justify-content-center align-items-center py-2">
            <div className="row container container-fluid-sm d-flex justify-content-center align-items-center">
                <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 py-2 center">
                    <img src={footer} className="footer-logo" alt="logo" />
                </div>
                <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 d-flex justify-content-center align-items-center py-2 center">
                    <h6 className="footer-para text-center hide-footer-para font-helvetica grey">{others[2].rightsReserved}</h6>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 d-flex justify-content-end py-2 center">
                    <a href={others[3].socials.linkedIn} className="px-2"><i className="fab fa-linkedin-in text-center"></i></a>
                    <a href={others[3].socials.facebook} className="px-2"><i className="fab fa-facebook-f text-center"></i></a>
                    <a href={others[3].socials.instagram} className="px-2"><i className="fab fa-instagram text-center"></i></a>
                </div>
            </div>
        </div>
    )
} 